/**
 * Query key builders for todo-related queries
 */
export const todoQueryKeys = {
  projectTodolists: ({
    projectId,
    todolistId,
    isTemplate = false,
    todosCount = false,
    queryParams = {},
  } = {}) => {
    if (!projectId && !todolistId) {
      return null;
    }
    const $join = [
      ...(isTemplate ? ['milestoneTemplate'] : ['milestone']),
      ...(todosCount ? ['todosCount'] : []),
      ...(queryParams?.$join || []),
    ];
    if (todolistId) {
      return [
        'tw/todolists',
        todolistId,
        {
          ...queryParams,
          $join,
        },
      ];
    }
    return [
      'tw/todolists',
      'find',
      {
        $sort: { position: 1, todolistId: 1 },
        projectId,
        completed: 0,
        $limit: -1,
        isTemplate,
        ...queryParams,
        $join,
      },
    ];
  },

  projectTodos: ({ projectId, todolistId, queryParams = {}, includeCustomFields = false } = {}) => {
    if (!projectId && !todolistId) {
      return null;
    }
    return [
      'tw/todos',
      'find',
      {
        ...(projectId && { projectId }),
        ...(todolistId && { todolistId }),
        completedAt: null,
        $sort: { position: 1 },
        $join: includeCustomFields ? ['commentsCountRead', 'customFields'] : ['commentsCountRead'],
        $limit: -1,
        ...queryParams,
      },
    ];
  },

  allProjectTodos: ({
    projectId = null,
    todolistIds,
    queryParams = {},
    includeCustomFields = false,
  } = {}) => {
    if (!projectId && !todolistIds) {
      return null;
    }
    return [
      'tw/todos',
      'find',
      {
        completedAt: null,
        projectId,
        todolistCompleted: 0,
        todolistDeletedAt: null,
        ...(todolistIds && todolistIds.length > 0 ? { todolistId: { $in: todolistIds } } : {}),
        $join: [
          'todolist',
          'responsibleUser',
          'responsibleCompany',
          'commentsCountRead',
          ...(includeCustomFields ? ['customFields'] : []),
          'creator',
        ],
        $sort: {
          createdAt: -1,
          dueDate: -1,
          content: 1,
        },
        $limit: -1,
        $client: { for: 'stripHtml' },
        ...queryParams,
      },
    ];
  },

  completedTodos: ({
    projectId,
    todolistId,
    queryParams = {},
    includeCustomFields = false,
  } = {}) => {
    if (!projectId && !todolistId) {
      return null;
    }
    //we ignore any limit here
    const { $limit, ...rest } = queryParams;
    return [
      'tw/todos',
      'find',
      {
        todolistId,
        completedAt: { $ne: null },
        $sort: { completedAt: -1 },
        $join: includeCustomFields ? ['commentsCountRead', 'customFields'] : ['commentsCountRead'],
        ...rest,
      },
    ];
  },

  bulkCompletedTodos: ({ projectId, todolistId, queryParams = {} } = {}) => {
    return [
      'tw/bulk-completed-todos',
      'find',
      {
        ...(projectId && { projectId }),
        ...(todolistId && { todolistId }),
        $limit: 3,
        ...queryParams,
      },
    ];
  },

  singleTodo: ({ todoId, queryParams, $joins } = {}) => {
    if (!todoId) {
      return null;
    }
    return [
      'tw/todos',
      todoId,
      {
        $ignoreDeletedAt: true,
        $join: {
          todolist: true,
          subscribers: {
            $join: ['user'],
          },
          deleter: true,
          ...$joins,
        },
        ...queryParams,
      },
    ];
  },
};
