import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { baseStyle } from './thumbnail.style';

const DropzoneFilesUpload = ({
  handleImageUpload,
  placeHolder,
  allowedFilesType,
  showImagePreviewOnDragDiv,
  avatarStyle = {},
  handleFilesDropped = () => {},
  handleFilesRejected = () => {},
  disabled,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: allowedFilesType,
    onDropAccepted: (acceptedFiles) => {
      handleImageUpload(acceptedFiles);
    },
    onDrop: handleFilesDropped,
    onDropRejected: handleFilesRejected,
  });

  let avatarStyling = {};
  let divStyle = {};
  if (showImagePreviewOnDragDiv) {
    avatarStyling = {
      position: 'absolute',
      display: ' inline-flex',
      width: '156px',
      height: '156px',
      ...avatarStyle,
    };

    divStyle = {
      borderRadius: ' 80px',
      padding: '44px 0px 0px 15px',
      ...(disabled && { cursor: 'default' }),
    };
  }

  const style = useMemo(() => ({
    ...baseStyle,
    ...divStyle,
  }));

  return (
    <section style={avatarStyling}>
      <div {...getRootProps({ className: 'dropzone', style })}>
        <input {...getInputProps()} disabled={disabled} />
        <span>{placeHolder}</span>
      </div>
    </section>
  );
};

export default DropzoneFilesUpload;
