import { Button, Icon } from '@webfx/core-web';
import { useNotifications } from '@webfx/web-hooks';

import Modal from 'react-bootstrap/Modal';

const ShareDashboardLinkModal = ({ show, setShow, sharedLink }) => {
  const { toast } = useNotifications();

  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <Icon outlined={true} className="font-24 text-blue-vivid-200">
            insert_drive_file
          </Icon>
          <span className="font-18 bold text-black ml-2">Share Dashboard Link</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center font-14 text-black mt-2 mb-4">
          This is a shareable link to your Current Playbook. You can use this link to share this
          report with any project stakeholders or colleagues even if they do not have access to
          TeamworkFX/RevenueCloudFX.
        </div>
        <div className="d-flex row justify-content-center mb-4">
          <div className="col-10 text-gray-550 rounded border border-secondary py-2 mb-3">
            {sharedLink}
          </div>
          <Button
            type="button"
            text="Copy link to clipboard"
            variant="primary"
            icon="content_copy"
            className={'btn btn-primary bg-blue-vivid-300 bg-hover-blue-400 font-14 bold'}
            onClick={() => {
              navigator.clipboard.writeText(sharedLink);
              toast.success('Your report URL has been copied to clipboard!');
              setShow(false);
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareDashboardLinkModal;
