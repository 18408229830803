import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

/**
 * Web hook to query deals channel stats.
 * @param params
 */
const useDealChannelStats = (params = {}) => {
  const { siteId, children } = useActiveSite({ $join: ['children'] });
  const siteIds = [siteId, ...(children?.map((child) => child.siteId) ?? [])];

  return api.useQuery(
    ['/revops/deal-channel-stats', { query: { siteId: { $in: siteIds }, ...params } }],
    {
      enabled: !!siteIds,
      select: (data) => data.data ?? data ?? [],
      placeholderData: [],
    }
  );
};

export default useDealChannelStats;
