import { createStore } from 'easy-peasy';

import companies from './companies';
import config from './config';
import imports from './imports';
import router from './router';
import sites from './sites';

const middlewares = [];

const store = createStore(
  {
    sites,
    config,
    companies,
    imports,
    router,
  },
  {
    middleware: middlewares,
    devTools: process.env.NODE_ENV === 'development',
  }
);

export default store;
