import { useCan } from '@webfx/web-hooks';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onClickTeamworkFxSearchMilestoneTitle = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxSearchPage',
  useGlobalLoader: false,
  action: async (actions, { milestoneId, projectId, pushParams }, getStoreState) => {
    const { onGetMilestone, clearSelectedMilestone } = actions.project.calendar.calendar;

    await Promise.all([onGetMilestone({ milestoneId })]);

    const state = getStoreState();
    const { dueAt, creatorUserId } = state.project.calendar.calendar.milestones.select;
    // eslint-disable-next-line react-hooks/rules-of-hooks -- will be deleted
    const editOtherEventOrMilestone = useCan(
      'update',
      'milestone',
      { projectId, creatorUserId },
      'title'
    );

    const startOf = dueAt.replace(/-\d{2}$/, '-01');

    // eslint-disable-next-line no-param-reassign -- will be deleted
    pushParams.openUpdateModal = editOtherEventOrMilestone;
    // eslint-disable-next-line no-param-reassign -- will be deleted
    pushParams.queryString = `?from_date=${startOf}`;
    if (!editOtherEventOrMilestone) {
      clearSelectedMilestone();
    }

    return `/projects/${projectId}/milestones?from_date=${startOf}`;
  },
});
