import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import classnames from 'classnames';

import { useFlags, useAuth, useRouter } from '@webfx/web-hooks';
import { feathers, Icon, Link, Image } from '@webfx/core-web';
import { QuarterSelector, ProductLogo } from '@webfx/web-ui';
import { useGamePlanKanbanView } from '@webfx/gameplan-ui';

import useCompanySites from '@webfx/teamwork-web/src/hooks/useCompanySites';

import CompanyStats from '@webfx/web-ui-connected/src/Contributors/SubHeader/CompanyStats';

import ShareDashboardLinkModal from '../../ShareDashboardLinkModal';

import useSitesPBEnabled from '../../../hooks/useSitesCurrentPBEnabled';

import styles from './Header.module.css';

const Header = ({ setQuarter, quarter, quarterSetting, year, forPlaybook, companyId }) => {
  const flags = useFlags();
  const { isAdmin, isGuest } = useAuth();

  const { url } = useRouter();

  const { data: playbookKanbanMonthViewSetting } = useGamePlanKanbanView(companyId);
  const { kanbanMonthView } = playbookKanbanMonthViewSetting;

  const isQuarterly = kanbanMonthView === 'quarterly';

  const [show, setShow] = useState(false);
  const [sharedLink, setSharedLink] = useState(null);

  const { data: company } = useCompanySites(companyId);
  const { data: sites } = useSitesPBEnabled(company?.sites?.map((site) => site.siteId));

  // Get siteId for Current Playbook from URL params
  let siteId = useParams().siteId;

  // Get siteId for TFWX overview from company sites with Current Playbook enabled in PlaybookFX
  if (company && sites?.data?.length) {
    siteId = sites.data[0]?.siteId;
  }

  useEffect(() => {
    let isMounted = true;

    const fetchSharedLink = async () => {
      if (siteId) {
        await getSharedLink();
      }
    };

    const getSharedLink = async () => {
      await feathers()
        .service('publicTokens')
        .find({
          query: {
            role: 'pbfxGamePlan',
            siteId,
            $limit: 1,
          },
        })
        .then((existingToken) => {
          // Only set the shared link if the component is still mounted
          if (isMounted) {
            if (existingToken.total) {
              setSharedLink(
                `${process.env.POI_APP_URL}/playbookfx/${siteId}/current-playbook?accessToken=${existingToken.data[0].token}`
              );
            } else {
              (async () => {
                await feathers()
                  .service('publicTokens')
                  .create({
                    role: 'pbfxGamePlan',
                    siteId,
                    expiration: null,
                  })
                  .then((newToken) => {
                    if (isMounted) {
                      setSharedLink(
                        `${process.env.POI_APP_URL}/playbookfx/${siteId}/current-playbook?accessToken=${newToken.token}`
                      );
                    }
                  });
              })();
            }
          }
        });
    };

    fetchSharedLink();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [siteId]);

  return (
    <div className="p-3 mb-3" data-fx-name="header">
      <div
        className={classnames(
          'd-flex align-items-center justify-content-between p-2 mb-3',
          styles.header,
          !forPlaybook ? [styles.headerBg] : 'bg-transparent p-0'
        )}
      >
        <div className="d-flex align-items-center">
          {!flags.gameplanfxUpdates ? (
            <p className="font-20 font-weight-bold mt-3">Current Playbook</p>
          ) : (
            <ProductLogo
              product="GamePlanFX"
              height="38px"
              className="text-gray-900"
              data-fx-name="gamePlanFxIcon"
            />
          )}

          {!isGuest && siteId && (
            <>
              <Icon className="font-13 text-blue-vivid-300 ml-4">link</Icon>
              <span
                className="cursor-pointer text-hover-blue-500 font-14 bold text-blue-700 ml-2"
                onClick={() => setShow(true)}
                data-fx-name="shareDashboardLink"
              >
                Share Dashboard Link
              </span>
            </>
          )}
        </div>

        {isAdmin && flags.gameplanfxUpdatesPhase2 && (
          <div className="d-flex align-items-center mx-auto">
            <Icon className={`text-red-03 mr-2`} data-fx-name="lockIcon">
              lock
            </Icon>
            <div className="font-13" data-fx-name="lockIconText">
              <span className="bold">FXers: </span>
              {`Toggle features or adjust client settings in `}

              <Link
                className="bold"
                to={url({ route: 'editCompany', id: companyId })}
                plain={true}
                data-fx-name="globalPermissionsLink"
              >
                Global Permissions
              </Link>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center">
          <ShareDashboardLinkModal show={show} setShow={setShow} sharedLink={sharedLink} />
        </div>

        <div className="d-flex align-items-center justify-content-end">
          {flags.gameplanfxUpdates && (
            <Image
              src={company?.logoUrl}
              className={`${styles.logo} mr-3`}
              data-fx-name="clientLogo"
            />
          )}

          <span className="mr-1">
            {isQuarterly || !flags.gameplanfxUpdates ? 'Quarter: ' : 'Starting Quarter: '}
          </span>
          <QuarterSelector
            companyId={companyId}
            setQuarter={setQuarter}
            quarter={quarter}
            quarterSetting={quarterSetting}
            year={year}
            cutoff={'2022-10-01'}
          />
        </div>
      </div>

      {flags.gameplanfxUpdates && <CompanyStats companyId={companyId} fromPlaybook={true} />}
    </div>
  );
};

export default Header;
