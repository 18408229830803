import classnames from 'classnames';

import styles from './Paper.module.css';

const Paper = ({ children, className, noPadding = false, id = '', ...props }) => (
  <div
    id={id}
    className={classnames(styles.paper, { [styles.noPadding]: noPadding }, className)}
    data-fx-name={props['data-fx-name'] || 'pageContainer'}
  >
    {children}
  </div>
);

export default Paper;
