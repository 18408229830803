import { useMemo } from 'react';
import classnames from 'classnames';
import { Doughnut } from 'react-chartjs-2';

import { colors } from '../../constants/goals';

import Goal from './Goal';

import styles from './GoalsChart.module.css';

const GoalsChart = ({ goals }) => {
  const getOptimizations = (optimizations) => {
    const result = optimizations[0];
    return result ?? {};
  };

  const { chartData, goalsData } = useMemo(() => {
    // If goals are not defined, return an empty array
    if (!goals) {
      return [];
    }

    // Calculate the total value of all goals
    const totalValue = goals.reduce((total, goal) => {
      const { total: goalValue = 0 } = getOptimizations(goal.optimizations);
      return total + goalValue;
    }, 0);

    // Map each goal to the desired structure
    // return (
    const goalsData = goals
      .map((goal, index) => {
        const {
          total: goalTotal = 0,
          assets = 0,
          optimizations = 0,
          completed = 0,
        } = getOptimizations(goal.optimizations);

        // Calculate the percentage of the total value for this goal
        const percentage = (goalTotal / totalValue) * 100;

        // Return the transformed goal object
        return {
          name: goal.name,
          label: `${goal.name} (${assets} FuelFX Assets, ${optimizations} FuelFX Optimizations)`,
          asset: assets,
          opt: goal.optimizations,
          value: goalTotal,
          completed,
          percentage: `${Math.round(percentage)}%`,
          color: !goal.isClientRequest ? colors[index] : '#CED4DA',
        };
      })
      // Sort goals by their total value in descending order
      .sort((a, b) => b.value - a.value);

    const dataset = goalsData.reduce(
      (acc, goal) => {
        acc.data.push(goal.value);
        acc.backgroundColor.push(goal.color);
        acc.borderColor.push('white');
        acc.percentage.push(goal.percentage);
        return acc;
      },
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        percentage: [],
      }
    );

    return {
      chartData: {
        labels: goalsData.map((goal) => goal.label),
        datasets: [dataset],
      },
      goalsData,
    };
  }, [goals]);

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        enabled: false,
        external(context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          const tooltipModel = context.tooltip;

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          // Set Text
          if (tooltipModel.title) {
            const titleLines = tooltipModel.title || [];

            tooltipEl.innerHTML = '';
            titleLines.forEach(function (title) {
              const p = document.createElement('p');
              p.className = 'm-0';
              p.textContent = title;
              tooltipEl.appendChild(p);
            });
          }

          const position = context.chart.canvas.getBoundingClientRect();

          const padding = (tooltipModel.padding = '4px');
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.padding = padding;
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.maxWidth = '250px';
          tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
          tooltipEl.style.borderRadius = '4px';
          tooltipEl.style.color = 'white';
          tooltipEl.style.fontSize = '12px';
        },
      },
    },
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className={classnames('col-md-5 pb-3', styles.chart)}>
        <Doughnut data={chartData} options={chartOptions} height={300} />
      </div>
      <div className="col-md-7">
        <div className={styles.gridContainer}>
          {goalsData.map((goal) => (
            <Goal key={goal.name} goal={goal} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoalsChart;
