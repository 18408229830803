export const transformGoogleDriveUrl = (url) => {
  if (!url) {
    return url;
  }

  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.host !== 'drive.google.com') {
      return url;
    }

    const idMatch = url.match(/id=([^&]+)/);
    if (!idMatch) {
      return url;
    }

    return `https://lh3.googleusercontent.com/d/${idMatch[1]}=w200-h200`;
  } catch (e) {
    return url;
  }
};
