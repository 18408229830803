import authentication from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import Cookies from 'js-cookie';
const feathersClient = feathers();

import traverser from 'traverse';

function _traverse(items /* modified */, converter) {
  (Array.isArray(items) ? items : [items]).forEach((item) => {
    traverser(item).forEach(converter); // replacement is in place
  });
}

const nuller = function (node) {
  if (node === null) {
    // eslint-disable-next-line no-invalid-this -- note using all caps as simple verification
    this.update('NULL');
  }
};

const customFetch = (url, options) => {
  const headers = options.headers || {};

  // Check and remove duplicate headers
  ['Content-Type', 'Accept'].forEach((header) => {
    const lowerCaseHeader = header.toLowerCase();

    if (headers[header] && headers[lowerCaseHeader]) {
      delete headers[lowerCaseHeader];
    }
  });

  return window.fetch(url, options);
};

const getCookieOptions = () => {
  if (process.env.POI_APP_URL !== 'http://localhost:4000') {
    return { domain: process.env.POI_APP_COOKIE_DOMAIN, secure: true };
  }

  return {};
};

// eslint-disable-next-line no-unused-vars -- unused
export const setClient = (api = '__API__', args = {}) => {
  if (!/https?/.test(api)) {
    return;
  }

  const restClient = rest(api);
  feathersClient.configure(restClient.fetch(customFetch));

  feathersClient.configure(
    authentication({ storage: window.localStorage, storageKey: 'accessToken' })
  );

  feathersClient.hooks({
    before: {
      all: [
        (context) => {
          _traverse(context.params.query, nuller);
          return context;
        },
      ],
    },
  });

  feathersClient.service('authentication').hooks({
    before: {
      create: [
        (context) => {
          // eslint-disable-next-line no-undef -- Add the DataDog RUM session info into the context
          const ic = DD_RUM?.getInternalContext() || null;
          if (ic?.session_id) {
            context.data.ddInfo = ic;
          }

          return context;
        },
      ],
    },
    after: {
      create: [
        (context) => {
          const { accessToken } = context.result;
          Cookies.set('fx_auth', accessToken, { ...getCookieOptions() });
          return context;
        },
      ],
      remove: [
        (context) => {
          Cookies.remove('fx_auth', { ...getCookieOptions() });
          return context;
        },
      ],
    },
    error: {
      all: (context) => {
        if (context.error.name === 'timeout') {
          localStorage.clear();
          Cookies.remove('fx_auth', { ...getCookieOptions() });
          Cookies.remove('GCLB', { ...getCookieOptions() });
          window.location.reload();
        }
      },
    },
  });
};

const getClient = () => feathersClient;

export default getClient;
