'use strict';

/**
 *
 * @param root0
 * @param root0.file
 * @param root0.apiUpload
 * @param root0.handleSetUploadedFile
 * @param root0.setFile
 * @param root0.uploadFile
 * @param root0.formObject
 * @param root0.index
 * @param root0.invalidateQuery
 */
const handleUpload = ({
  file,
  apiUpload,
  handleSetUploadedFile,
  setFile,
  uploadFile,
  formObject,
  index,
  invalidateQuery,
}) => {
  const request = uploadFile({ file, apiUpload, projectId: formObject?.projectId });
  request.then((response) => {
    const { data } = response;

    handleSetUploadedFile({
      file: {
        ...file,
        ...data,
      },
      index,
    });
    setFile({
      ...response.data,
      preview: file.preview,
    });
    invalidateQuery(formObject);
  });

  request.catch((_error) => {
    const failedUpload = {
      ...file,
      projectId: formObject?.projectId,
      name: file.name,
      type: file.type,
      fileData: file.fileData,
    };
    failedUpload.status = 'failed';

    setFile(failedUpload);
  });
};

export default handleUpload;
