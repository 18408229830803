'use strict';

const removeFile =
  ({ mutator, onFileRemove, setFile }) =>
  ({ uploadId, formObject, isAvatar, avatarUrl, file, fileIndex }) => {
    //if image is an avatar
    if (isAvatar) {
      let id;

      //get avatar upload id from url
      if (avatarUrl) {
        id = parseInt(avatarUrl.split('uploads/').pop());
      }

      if (file.uploadId) {
        id = file.uploadId;
      }

      if (!id) {
        return null;
      }

      const params = {
        _method: 'DELETE',
        _id: id,
        _params: {
          query: {
            ...formObject,
          },
        },
      };

      mutator.mutate(params, {
        onSuccess: (_response) => {
          onFileRemove({ removedFile: file, fileSet: setFile, fileIndex, avatarUrl });
        },
        onError: (_err) => {
          onFileRemove({ removedFile: file, fileSet: setFile, fileIndex, avatarUrl });
        },
      });
    }

    if (uploadId && !isAvatar) {
      const params = {
        _method: 'DELETE',
        _id: uploadId,
      };

      mutator.mutate(params, {
        onSuccess: (_response) => {
          onFileRemove({ removedFile: file, fileSet: setFile, fileIndex });
        },
      });
    }
  };

export default removeFile;
