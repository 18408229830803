import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import useDealChannelStats from '@webfx/marketingcloud-web/src/hooks/analytics/useDealChannelStats';
import useDeals from '@webfx/marketingcloud-web/src/hooks/useDeals';
import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

const useIntegrationSetup = () => {
  const [requiresIntegration, setRequiresIntegration] = useState(false);

  // Get whether this site or any of its children have CRM integration enabled.
  const { siteId, children } = useActiveSite({ $join: ['children'] });
  const { data: isCrmIntegrated, isFetched: isCrmIntegratedFetched } = api.useQuery(
    [
      'sites',
      {
        query: {
          siteId: { $in: [siteId, ...(children?.map((child) => child.siteId) ?? [])] },
          $join: ['crmIntegrated'],
        },
      },
    ],
    {
      enabled: !!siteId,
      select: (data) => {
        return data.data.reduce((acc, site) => {
          return acc || site.crmIntegrated?.isIntegrated;
        }, false);
      },
    }
  );

  const startDate = DateTime.local().minus({ days: 90 }).toISODate();
  const endDate = DateTime.local().toISODate();
  const { data: wonDeals, isFetched } = useDealChannelStats({
    startDate,
    endDate,
    dateColumn: 'close_at',
    category: 'closed won',
  });

  const { data: crmDeals, isFetched: isCrmDealsFetched } = useDeals({
    closeAt: {
      $gte: startDate,
      $lte: endDate,
    },
    category: 'closed won',
    isIngestionDeal: false,
  });

  useEffect(() => {
    if (!isFetched || !isCrmIntegratedFetched || !isCrmDealsFetched) {
      return;
    }

    if (isCrmIntegrated && crmDeals?.total === 0) {
      setRequiresIntegration(true);
      return;
    }

    if (wonDeals?.totalCount === 0) {
      setRequiresIntegration(true);
    }
  }, [isFetched, isCrmDealsFetched, isCrmIntegratedFetched]);

  return {
    requiresIntegration,
    setRequiresIntegration,
  };
};

export default useIntegrationSetup;
