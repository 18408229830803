import { api } from '@webfx/core-web';
import { todoQueryKeys } from '@webfx/teamwork-web/src/utils/todos-cache/queryKeys';
import { useNotifications, useStartPage } from '@webfx/web-hooks';
import { useHistory } from 'react-router-dom';

export const useTodo = (todoId, queryOpts = {}, queryParams = {}) => {
  const { toast } = useNotifications();
  const startPage = useStartPage();
  const history = useHistory();

  return api.useQuery(
    todoQueryKeys.singleTodo({
      todoId,
      queryParams,
    }),
    {
      ...queryOpts,
      enabled: !!todoId,
      onError: (error) => {
        queryOpts?.onError?.(error);

        if (error.code === 405) {
          toast.error('You do not have access to that project');
          history.push(startPage);
        }
      },
    }
  );
};
