import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

const useDeals = (query = {}) => {
  const { siteId, children } = useActiveSite({ $join: ['children'] });
  const siteIds = [siteId, ...(children?.map((child) => child.siteId) ?? [])];

  return api.useQuery(['revops/deals', { siteId: { $in: siteIds }, ...query }], {
    enabled: !!siteIds,
  });
};

export default useDeals;
