import { convert } from 'html-to-text';
import * as Yup from 'yup';

import classnames from 'classnames';
import { feathers, Icon } from '@webfx/core-web';
import { createdByOptions } from './constants';

// we want to format old JSON KeyTakeaways to be a single html string.
/**
 * This function retrieves the value of a key takeaway.
 * @param {Array} keyTakeaways - The array of key takeaways.
 * @returns {string} - The HTML string of key takeaways.
 */
export const getKeyTakeawayValue = (keyTakeaways) => {
  let values;
  try {
    values = JSON.parse(keyTakeaways).map((v) => `<li>${v.value}</li>`);
    if (values.length) {
      values = `<ul>${values.join('')}</ul>`;
    } else {
      values = '';
    }
  } catch (e) {
    values = keyTakeaways || '';
  }

  return values;
};

/**
 * Generates a shareable link for a report.
 * @param {number} siteId - The ID of the site.
 * @param {number|string} reportId - The report ID or report name.
 * @returns {Promise<string>} - A promise that resolves to the shareable link.
 */
export const generateReportShareLink = async (siteId, reportId) => {
  const token = await feathers().service('publicTokens').create({
    role: 'mcfxAnalyticsReports',
    siteId,
  });

  const port = location.port ? `:${location.port}` : '';

  return `${location.protocol}//${location.hostname}${port}/revenuecloudfx/${siteId}/analytics/reports/${reportId}?accessToken=${token.token}`;
};

/**
 * Generates a shareable link for the contributors page.
 * @param {number} siteId - The ID of the site.
 * @returns {Promise<string>} - A promise that resolves to the shareable link.
 */
export const generateContributorsShareLink = async (siteId) => {
  const token = await feathers().service('publicTokens').create({
    role: 'mcfxAnalyticsContributors',
    siteId,
  });

  const port = location.port ? `:${location.port}` : '';

  return `${location.protocol}//${location.hostname}${port}/revenuecloudfx/${siteId}/analytics/contributors?accessToken=${token.token}`;
};

/**
 * Helper function to standardize report URL for forms.
 * @param {string} url - The original URL to be standardized.
 * @returns {string} - The standardized URL.
 */
export const formatReportUrl = (url) => {
  url = url.trim();

  if (
    url.toLowerCase().startsWith('https://drive.google.com') ||
    url.toLowerCase().startsWith('https://docs.google.com')
  ) {
    url = url.replace(/\/(preview|edit|view|export).*$/g, '');
    url = `${url}/preview`;
  }

  // If the URL is an iframe, extract the src attribute
  if (checkIfValidIframe(url)) {
    url = extractReportUrl(url);
  }

  return url;
};

/**
 * Get the download link for a report.
 * @param {object} report - Description of the report parameter here.
 * @returns {string|undefined} - The download link for the report or undefined if the report is not available or the PDF url is not ready.
 */
export const getDownloadLink = (report) => {
  if (!report || (!report.reportUrl && !report.url)) {
    return null;
  }

  if (report.downloadUrl) {
    return report.downloadUrl;
  }

  let url = report.reportUrl ? report.reportUrl.trim() : report.url.trim(); // Keeping "url" for V1 support

  if (url.toLowerCase().startsWith('https://drive.google.com')) {
    url = url.replace(/\/(preview|edit|view|export).*$/g, '');
    const match = /https:\/\/drive\.google\.com\/file\/d\/(.*)/.exec(url);
    if (match) {
      url = `https://drive.google.com/u/0/uc?id=${match[1]}&export=download`;
    }
  } else if (url.toLowerCase().startsWith('https://docs.google.com')) {
    url = url.replace(/\/(preview|edit|view|export).*$/g, '');
  }

  return url;
};

export const openReportDocument = (report) => {
  const link = document.createElement('a');
  link.href = getDownloadLink(report);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const setCustomReportIcon = (index) => {
  const colors = [
    'text-teal-03',
    'text-purple-03',
    'text-orange-03',
    'text-blue-vivid-300',
    'text-green-03',
  ];

  return (
    <Icon outlined className={classnames([colors[index % colors.length], 'font-24 mr-2'])}>
      assessment
    </Icon>
  );
};

const categoryDescriptions = {
  1: 'SEO',
  2: 'PPC',
  3: 'CRO',
  4: 'Social Media',
  5: 'Local SEO',
  6: 'Email Marketing',
  7: 'Earned Media',
  9: 'Digital Marketing',
};

/**
 * Get the tooltip description for a custom report category.
 * @param {number} categoryId - The ID of the category.
 * @returns {string} - The tooltip description.
 */
export const getCustomReportTooltipDescription = (categoryId) => {
  const service = categoryDescriptions[categoryId];
  return service ? `One time reports and dashboards for your ${service} service` : '';
};

/**
 *
 * @param {string} section - The section name.
 * @returns {object} - The colors for the menu section.
 */
export const getSectionColors = (section) => {
  const sectionColors = {
    admin: {
      background: 'blue-00',
      icon: 'blue-400',
    },
    performance: {
      background: 'teal-01',
      icon: 'teal-05',
    },
    category: {
      background: 'purple-01',
      icon: 'purple-03',
    },
    mroi: {
      background: 'blue-100',
      icon: 'blue-vivid-300',
    },
  };

  if (!section) {
    return sectionColors;
  }

  return sectionColors[section];
};

/**
 * Validate if a soapbox embed code is valid.
 * @returns {boolean}
 */
export const soapboxValidation = Yup.string().test(
  'code-is-valid',
  "This does not appear to be a valid Soapbox embed code. Please make sure you have entered your video's embed code and not its link.",
  (value) => {
    if (!value || !value?.includes('soapbox')) {
      return true;
    }
    const regex = /<div><p><a.+<\/a><\/p><\/div>/g;
    if (value.match(regex)) {
      return true;
    }
    return false;
  }
);

/**
 * Validate that the Report URL only accepts Looker Studio and Data Studio embed links (or app upload URLs).
 * @returns {boolean}
 */
export const reportUrlValidation = Yup.string().test(
  'url-is-valid',
  'This does not appear to be a valid Looker Studio or Data Studio embed link. Please make sure you have entered the correct URL.',
  (value) => {
    if (value?.includes('webfx.com/uploads')) {
      return true;
    }

    const allowedHosts = ['lookerstudio.google.com', 'datastudio.google.com'];

    let url;
    // check if the field value is an iframe embed code
    if (checkIfValidIframe(value)) {
      // extract the src attribute from the iframe only if it exists
      const srcMatch = extractReportUrl(value);

      if (srcMatch) {
        //Check if the extracted src starts with either lookerUrl or datastudioUrl
        url = extractUrl(srcMatch);

        // Check if the host of the extracted src is in the list of allowed hosts
        return allowedHosts.includes(url.host) ? !!srcMatch : false;
      }
    }

    // Extract the URL from the report URL field - not embed code
    url = extractUrl(value);
    // Check if the host of the value is in the list of allowed hosts
    // Don't allow short URLs (e.g. https://lookerstudio.google.com/s/pu9eXnt5vOI)
    if (allowedHosts.includes(url.host) && !url.pathname.startsWith('/s/')) {
      return true;
    }
    return false;
  }
);

const extractUrl = (value) => {
  try {
    return new URL(value);
  } catch (_) {
    return false;
  }
};

// Check if value is an iframe with a src attribute
const checkIfValidIframe = (value) => {
  return /<iframe[^>]*src="[^"]*"[^>]*>/i.test(value);
};

const extractReportUrl = (embedCode) => {
  return embedCode.match(/src="([^"]*)"/i)[1];
};

/**
 * Sanitizes the value returned by Quill editor.
 * Quill saves "<p><br></p>" as the default value when the editor is empty.
 * This function will return an empty string in such cases.
 * @param {string} value - The value returned by Quill editor.
 * @returns {string} The sanitized value.
 */
export const sanitizeQuillValue = (value) => {
  const emptyParagraphRegex = /<p>\s*<\/p>/;
  if (value === '<p><br></p>' || emptyParagraphRegex.test(value)) {
    return '';
  }
  return value;
};

/**
 * Translates the createdBy filter for Looker embed use. This maps the API parameter values to the Looker filter values.
 * @param {string|Array} createdBy - The createdBy filter value(s).
 * @returns  {string} - The Looker filter value.
 */
export const translateCreatedByFilterForLooker = (createdBy) => {
  if (!createdBy) {
    return '';
  }

  if (Array.isArray(createdBy)) {
    return createdBy
      .map((filter) => createdByOptions.find((option) => option.value === filter)?.label)
      .join(',');
  } else {
    return createdByOptions.find((option) => option.value === createdBy)?.label;
  }
};

/**
 * Validates the Key Takeaways field to ensure it is not empty.
 * Quill saves "<p><br></p>" or "<h1><br></h1>" (or any other typr of heading)
 * as the default value when the editor is empty.
 */
export const validateTakeaways = Yup.string().test(
  'code-is-valid',
  'Takeaways are required',
  (value) => {
    const takeaways = convert(value);
    if (takeaways.trim() === '') {
      return false;
    }
    return true;
  }
);
