import { DateTime } from 'luxon';
import { PermissionsAbility } from '@webfx/web-state';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onClickTeamworkFxSearchEventTitle = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxSearchPage',
  useGlobalLoader: false,
  action: async (actions, { id, projectId, pushParams }, getStoreState) => {
    const { onSelectEvent, clearSelectedEvent } = actions.project.calendar.calendar;

    await onSelectEvent({ id });

    const state = getStoreState();
    const { startAt, creatorUserId } = state.project.calendar.calendar.events.select;
    const editOtherEventOrMilestone = PermissionsAbility.can(
      'update',
      'milestone',
      { projectId, creatorUserId },
      'title'
    );

    const startOf = startAt
      ? startAt.replace(/-\d{2}$/, '-01')
      : DateTime.local().toFormat('yyyy-LL-01');

    pushParams.openUpdateModal = editOtherEventOrMilestone;
    pushParams.queryString = `?from_date=${startOf}`;
    if (!editOtherEventOrMilestone) {
      clearSelectedEvent();
    }

    return `/projects/${projectId}/milestones?from_date=${startOf}`;
  },
});
