import ReactSwitch from 'react-switch';

import classNames from 'classnames';
import InputLabel from '../Input/InputLabel/InputLabel';
import Icon from '../Icon/Icon';

import styles from './Switch.module.css';

// Little hack to work around react-switch transpiling error
let Switch = ReactSwitch;
if (typeof Switch === 'object') {
  Switch = Switch.default;
}

const SwitchComponent = ({
  className,
  label,
  binary = false,
  field: { name, value },
  form: { setFieldValue },
  variation = 'primary',
  onChange,
  id,
  isSmall,
  labelClassName,
  ...passThroughProps
}) => {
  // eslint-disable-next-line no-param-reassign -- id assigned to name if id not provided
  id = id ? id : name;

  return (
    <div className={`${styles.wrapper} ${className}`} data-fx-name="switch">
      <Switch
        data-fx-name="switchValue"
        checked={value}
        onChange={(val) => {
          // use value 1 and zero instead of bool
          const binaryValue = binary ? (val ? 1 : 0) : val;
          setFieldValue(name, binaryValue);
          if (onChange) {
            onChange(binaryValue);
          }
        }}
        handleDiameter={isSmall ? 10 : 14}
        uncheckedIcon={false}
        checkedIcon={
          <Icon
            className={classNames(styles.icon, isSmall ? styles.iconSmall : '')}
            size={isSmall ? 'extraSmall' : 'small'}
          >
            check
          </Icon>
        }
        boxShadow="none"
        onHandleColor="#fff"
        offHandleColor="#fff"
        offColor="#84919B"
        onColor={variation === 'success' ? '#26B16D' : '#4C74B9'}
        // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={isSmall ? 14 : 18}
        width={isSmall ? 26 : 40}
        className="react-switch"
        name={name}
        aria-labelledby={name}
        id={id}
        {...passThroughProps}
      />
      {label ? (
        <InputLabel
          htmlFor={name}
          text={label}
          className={classNames(styles.margins, labelClassName)}
        />
      ) : null}
    </div>
  );
};

export default SwitchComponent;
