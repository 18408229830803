export const CARD_PROPERTIES = {
  asset: {
    cardBackground: 'bg-blue-100',
    badgeBackground: 'bg-blue-200',
    textColor: 'text-blue-500',
    boxShadow: '-4px 0px 0px var(--blue-400)',
  },
  todo: {
    cardBackground: 'bg-orange-01',
    badgeBackground: 'bg-orange-02',
    textColor: 'text-orange-04',
    boxShadow: '-4px 0px 0px var(--orange-03)',
  },
  seo: {
    cardBackground: 'bg-orange-01',
    badgeBackground: 'bg-orange-02',
    textColor: 'text-orange-04',
    boxShadow: '-4px 0px 0px var(--orange-03)',
  },
  ppc: {
    cardBackground: 'bg-lime-01',
    badgeBackground: 'bg-lime-02',
    textColor: 'text-lime-05',
    boxShadow: '-4px 0px 0px var(--lime-03)',
  },
  social: {
    cardBackground: 'bg-teal-01',
    badgeBackground: 'bg-teal-02',
    textColor: 'text-teal-05',
    boxShadow: '-4px 0px 0px var(--teal-03)',
  },
  otherServices: {
    cardBackground: 'bg-gray-100',
    badgeBackground: 'bg-gray-200',
    textColor: 'text-gray-600',
    boxShadow: '-4px 0px 0px var(--gray-03)',
  },
};

export const BADGE_PROPERTIES = {
  director: {
    icon: 'star',
    label: 'Director’s Top Pick',
  },
  sme: {
    icon: 'bolt',
    label: 'SME Top Pick',
  },
  algorithmUpdate: {
    icon: 'rocket_launch',
    label: 'Algorithm Update Optimization',
  },
  seo: {
    icon: 'search',
    label: 'SEO',
  },
  ppc: {
    icon: 'ads_click',
    label: 'PPC',
  },
  redesign: {
    icon: 'palette',
    label: 'Redesign',
  },
  social: {
    icon: 'emoji_emotions',
    label: 'Social',
  },
};
