const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width: '100%',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const thumbDiv = {
  display: 'inline-flex',
  borderRadius: 6,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 290,
  height: 100,
  padding: 8,
  boxSizing: 'border-box',
  backgroundColor: '#e0dfde85',
};

const fileIcon = {
  fontSize: '90px',
  color: 'rgb(9 11 14 / 60%)',
};

const thumbWrapper = {
  marginRight: '14px',
  float: 'left',
};

const removeIcon = {
  fontSize: '30px',
  color: 'rgb(9 11 14 / 60%)',
  cursor: ' pointer',
};

const retryIcon = {
  fontSize: '30px',
  cursor: ' pointer',
  color: 'var(--danger)',
};

export const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  padding: '1rem',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'var(--gray-300)',
  borderStyle: 'dashed',
  backgroundColor: 'var(--gray-100)',
  color: 'var(--gray-500)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  position: 'relative',
};

export const avatarDiv = {
  backgroundColor: 'red',
};

export default {
  thumbInner,
  img,
  thumbDiv,
  removeIcon,
  fileIcon,
  thumbWrapper,
  baseStyle,
  retryIcon,
  avatarDiv,
};
