import classnames from 'classnames';

import { Icon } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

import useCompanySites from '@webfx/teamwork-web/src/hooks/useCompanySites';
import useCopyPageStats from '@webfx/contentgenius-web/src/hooks/useCopyPageStats';
import useReports from '@webfx/marketingcloud-web/src/hooks/analytics/useReports';
import useIntegrationSetup from '@webfx/marketingcloud-web/src/hooks/analytics/useIntegrationSetup';

import ActionItemsList from './ActionItemsList';

import styles from './ActionsNeeded.module.css';

const ActionsNeeded = ({ months, companyId }) => {
  const { siteId: activeSiteId } = useActiveSite();

  const { data: company } = useCompanySites(companyId);
  const siteId = activeSiteId || company?.sites?.[0]?.siteId;
  const pageStats = useCopyPageStats({ companyId, forCGFX: true, showHiddenItems: false });
  const { requiresIntegration: integrationFxConfigured } = useIntegrationSetup();

  const reportsQuery = {
    siteId,
    $limit: -1,
    $sort: { createdAt: -1 },
    createdAt: {
      $gte: months[0],
    },
  };
  const { data: reports } = useReports({ ...reportsQuery });

  const pendingApprovalItems = pageStats?.data?.[siteId]?.steps?.['Pending Approval'];

  const actionItems = {
    contentPendingApprovals: {
      totalCount: pendingApprovalItems?.totalCount || 0,
      over30Days: pendingApprovalItems?.over30Days || 0,
    },
    newReportsCount: reports?.filter((report) => !report.isViewed).length,
    integrationFxConfigured, // "IntegrationFX configured" indicates that there have been closed deals on the site in the last quarter (90 days)
  };

  return (
    <div className={classnames(`d-flex flex-column bg-orange-01 flex-fill`, styles.container)}>
      <div className="d-flex align-items-center px-2 py-3">
        <Icon className="font-24 mr-3 text-orange-04">warning</Icon>
        <div className="d-flex flex-column" style={{ lineHeight: 1.2 }}>
          <span className="font-18 bold">Actions Needed</span>
          <span className="font-14 text-gray-550">to help meet these objectives</span>
        </div>
      </div>

      <ActionItemsList actionItems={actionItems} siteId={siteId} />
    </div>
  );
};

export default ActionsNeeded;
