import { useHistory, useParams } from 'react-router-dom';
import { api } from '@webfx/core-web';
import { useNotifications, useStartPage } from '@webfx/web-hooks';
import { store } from '../contexts/ProjectContext';
import { useActivity } from './useActivity';
import useMessages from './messages/useMessages';

export default function useProject(_projectId, preload = false) {
  const { projectId: projectIdParam = null } = useParams();
  const { toast } = useNotifications();
  const storeProjectId = store.useTracked.projectId();
  const startPage = useStartPage();
  const history = useHistory();

  const projectId = Number(_projectId ?? projectIdParam ?? storeProjectId ?? 0);

  // this is for preloading activity data
  useActivity(preload && { projectId });
  useMessages(preload && projectId);

  return api.useQuery(
    [
      'tw/projects',
      projectId,
      {
        query: {
          $join: ['owner', 'counts', 'availableFields'],
        },
      },
    ],
    {
      enabled: !!projectId,
      staleTime: 1000 * 60 * 10,
      retry: false,
      retryOnMount: false,
      onError: (error) => {
        if (error.code === 405 || error.code === 404) {
          toast.error('You do not have access to that project');
          history.push(startPage);
        }
      },
    }
  );
}
