import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import QuarterlyGrowthPlan from './components/QuarterlyGrowthPlan';
import OptimizationFilters from './components/OptimizationFilters';
import OptimizationKanban from './components/OptimizationKanban/OptimizationKanban';
import ActivityLog from './components/ActivityLog/ActivityLog';
import GamePlan from './components/GamePlan/GamePlan';

import useGamePlanIsEnabled from './hooks/useGamePlanIsEnabled';
import useGamePlanKanbanView from './hooks/useGamePlanKanbanView';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  ChartDataLabels,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
);

ChartJS.defaults.set('plugins.datalabels', {
  color: '#111',
  display: false,
});

// Show cursor pointer when hovering over legend items
ChartJS.defaults.set('plugins.legend', {
  onHover: (event) => (event.native.target.style.cursor = 'pointer'),
  onLeave: (event) => (event.native.target.style.cursor = 'default'),
});

ChartJS.defaults.font.family = 'Inter';

export {
  QuarterlyGrowthPlan,
  OptimizationFilters,
  OptimizationKanban,
  useGamePlanIsEnabled,
  ActivityLog,
  GamePlan,
  useGamePlanKanbanView,
};
