import { action, actionOn, thunkOn, persist } from 'easy-peasy';
import uniq from 'lodash/uniq';
import { match } from 'path-to-regexp';

const sites = {
  activeId: null,
  lastActiveId: null,
  recentlyViewed: [],

  setActive: action((state, payload) => {
    state.activeId = payload === null ? null : Number(payload);
  }),

  setLastActive: action((state, payload) => {
    state.lastActiveId = payload === null ? state.lastActiveId : Number(payload);
  }),

  setLastActiveForce: action((state, payload) => {
    state.lastActiveId = payload === null ? null : Number(payload);
  }),

  setActiveOnRoute: thunkOn(
    (actions, storeActions) => storeActions.router.setLocation,
    (actions, target, { getState }) => {
      const routeMatch = match('/:section/:siteId(\\d+)?/(.*)?');
      const { pathname } = target.payload;
      const res = routeMatch(pathname);
      const apps = ['revenuecloudfx', 'playbookfx', 'competitorspy', 'contentgenius', 'nutshell'];

      if (res && res.params.siteId > 0 && apps.includes(res.params.section)) {
        if (getState().activeId !== Number(res.params.siteId)) {
          actions.setActive(Number(res.params.siteId));
        }
        actions.setLastActive(Number(res.params.siteId));
      }
    }
  ),

  updateRecentlyViewed: actionOn(
    (actions) => actions.setActive,
    (state, target) => {
      if (target.payload === null) {
        return;
      }

      const currentIndex = (state.recentlyViewed ?? []).findIndex((id) => id === target.payload);

      // add to front
      state.recentlyViewed.unshift(Number(target.payload));

      // remove any old index positions
      if (-1 !== currentIndex) {
        state.recentlyViewed.splice(currentIndex, 1);
      }

      state.recentlyViewed = uniq(state.recentlyViewed).slice(0, 10);
    }
  ),
};

export default persist(sites, {
  storage: 'localStorage',
  allow: ['recentlyViewed', 'lastActiveId'],
});
