import { api } from '@webfx/core-web';
import { useNotifications } from '@webfx/web-hooks';
import defaultUser from '@webfx/permissions-web/src/ui/pages/Users/User/defaultUser';

export default function useUser(userId, queryParams = {}, reactQueryParams = {}) {
  const { toast } = useNotifications();
  const queryClient = api.useQueryClient();

  const { data, ...passThroughQuery } = api.useQuery(
    [
      'users',
      Number(userId),
      {
        ...queryParams,
        query: {
          $cache: 0,
          $join: {
            companies: true,
            companiesUsers: true,
            products: true,
            sites: true,
            emails: true,
            active: true,
          },
          ...queryParams.query,
        },
      },
    ],
    {
      enabled: !!userId,
      ...reactQueryParams,
    }
  );

  const mutator = api.useMutation(['users', userId], 'userId', [passThroughQuery.queryKey], {
    onSuccess: (response, variables = {}) => {
      queryClient.invalidateQueries('users');
      if (variables._method?.toLowerCase() === 'patch' && Object.keys(variables)?.length <= 2) {
        return; // Do nothing on an empty patch request
      }
      toast.success(
        `${
          response.firstName || response.lastName || response.fullName || response.email
        } has been ${userId ? 'updated' : 'created'}.`
      );
    },
  });

  return {
    data: data ?? defaultUser,
    ...passThroughQuery,
    mutator,
  };
}
