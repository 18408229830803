import React, { Suspense } from 'react';

const Routes = React.lazy(() => import('./ui/Routes'));

export default function App() {
  return (
    <Suspense fallback={<div style={{ display: 'none' }} />}>
      <Routes />
    </Suspense>
  );
}
