import LazyLoad from 'react-lazy-load';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { parse } from 'url';

import DefaultAvatar from '../../assets/images/avatar.png';
import { transformGoogleDriveUrl } from '../../utils/transformGoogleDriveUrl';

import styles from './Avatar.module.css';

/**
 * There was a strange behaviour with default param for @src
 * e.g. `const Avatar = ({src = defaultAvatar}) => *rest of code*`
 * src === null, @defaultAvatar value isn't apply the same for empty `string`
 * So the solution is move the `condition` to `src` property and check from this place.
 */

const Image = ({
  src,
  alt = 'Avatar',
  className,
  disableLazyLoad = false,
  ...passThroughProps
}) => {
  const handleImageError = ({ currentTarget }) => {
    currentTarget.onError = null;
    currentTarget.src = DefaultAvatar;
  };

  const transformedSrc = transformGoogleDriveUrl(src);
  const allowedHosts = ['drive.google.com'];
  const isGoogleDrive = src && allowedHosts.includes(parse(src).host);

  return (
    <>
      {disableLazyLoad ? (
        <img
          className={classnames(styles.avatar, className)}
          onError={handleImageError}
          {...passThroughProps}
          src={transformedSrc || DefaultAvatar}
          alt={alt}
          {...(isGoogleDrive && { referrerPolicy: 'no-referrer' })}
        />
      ) : (
        <LazyLoad className={className}>
          <img
            className={classnames(styles.avatar, className)}
            data-fx-name="userAvatar"
            onError={handleImageError}
            {...passThroughProps}
            src={transformedSrc || DefaultAvatar}
            alt={alt}
            loading="lazy"
            {...(isGoogleDrive && { referrerPolicy: 'no-referrer' })}
          />
        </LazyLoad>
      )}
    </>
  );
};

const Avatar = ({ tooltip, ...passThroughProps }) => {
  if (!tooltip) {
    return <Image {...passThroughProps} />;
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
      <Image {...passThroughProps} />
    </OverlayTrigger>
  );
};

export default Avatar;
