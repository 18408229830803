/**
 * @param {object} param0
 * @param {object} param0.weeks
 * @param {number[]} param0.weeks.list - the list of week numbers
 * @param {object} param0.weeks.map - the map of week numbers to days
 * @param {object} param0.weeks.map[number] - the map of week numbers to days
 * @param {number} param0.weeks.map[number].days - the map of week numbers to days
 * @param {number} param0.weeks.map[number].days.list - the list of days
 * @param {number} param0.weeks.map[number].days[number] - the map of days to milestones
 * @param {number} param0.weeks.map[number].days[number].list - the list of milestones
 * @param {number} param0.deletedWeek - the zero-indexed week number
 * @param {number} param0.deletedDay - the zero-indexed day number (0-6)
 * @param {number} param0.deletedMilestone - the zero-indexed milestone number
 * @returns {void}
 */
export const removeMilestoneHelper = ({ weeks, deletedWeek, deletedDay, deletedMilestone }) => {
  if (
    weeks?.map[deletedWeek]?.days?.list?.length === 1 &&
    weeks?.map[deletedWeek]?.days[deletedDay]?.list?.length === 1
  ) {
    const deleteWeekIndex = weeks.list.findIndex((item) => item === deletedWeek);

    delete weeks.map[deletedWeek];
    weeks.list.splice(deleteWeekIndex, 1);
    return;
  }

  if (weeks?.map[deletedWeek]?.days[deletedDay]?.list?.length === 1) {
    const deleteDayIndex = weeks?.map[deletedWeek]?.days?.list?.findIndex(
      (item) => item === deletedDay
    );

    delete weeks?.map[deletedWeek]?.days[deletedDay];
    weeks?.map[deletedWeek]?.days?.list?.splice(deleteDayIndex, 1);
    return;
  }

  const deleteMilestoneIndex = weeks?.map[deletedWeek]?.days[deletedDay]?.list?.findIndex(
    (item) => item === deletedMilestone
  );

  delete weeks?.map[deletedWeek]?.days[deletedDay]?.map[deletedMilestone];
  weeks?.map[deletedWeek]?.days[deletedDay]?.list?.splice(deleteMilestoneIndex, 1);
};
