import { onMountProjectSettings } from './project/thunks/onMountProjectSettings';

import settings from './settings';
import deletions from './deletions';
import search from './search';

const project = {
  onMountProjectSettings,

  settings,
  deletions,
  search,
};

export default project;
