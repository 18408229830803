import { useMemo } from 'react';
import { api } from '@webfx/core-web';
import { useNotifications, useStartPage } from '@webfx/web-hooks';
import { useHistory } from 'react-router-dom';

export default function useMessage(messageId, reactQueryParams = {}) {
  const { toast } = useNotifications();
  const startPage = useStartPage();
  const history = useHistory();

  const query = api.useQuery(
    [
      'tw/messages',
      messageId,
      {
        $join: {
          category: true,
          creator: true,
          attachments: true,
          pinnedBy: true,
          subscribers: {
            $join: ['user'],
          },
          comments: {
            $join: ['creator', 'uploads'],
          },
        },
      },
    ],
    {
      enabled: !!messageId,
      onError: (error) => {
        if (error.code === 405) {
          toast.error('You do not have access to that project');
          history.push(startPage);
        }
      },
      ...reactQueryParams,
    }
  );

  // re-formats comments for the ui
  const comments = useMemo(() => {
    const comments = query.data?.comments ?? [];
    const commentsMap = {};
    comments.forEach((comment) => {
      commentsMap[comment.commentId] = { ...comment };
    });

    return {
      total: comments.length,
      list: comments.map((comment) => comment.commentId),
      map: commentsMap,
      firstCommentId: null,
    };
  }, [query.data]);

  return {
    comments,
    message: query.data,
    isLoading: query.isLoading,
  };
}
