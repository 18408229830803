// import { onMountTeamworkFx } from './teamworkFx/thunks/onMountTeamworkFx';

import dashboard from './dashboard';
import todos from './todos';
import search from './search';
import templates from './templates';
// import calendar from './calendar';
// import allPeople from './allPeople';

const teamworkFx = {
  // thunks
  // onMountTeamworkFx,
  // tabs
  dashboard,
  todos,
  search,
  templates,
  // calendar,
  // allPeople,
};

export default teamworkFx;
